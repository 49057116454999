import { ChainId } from '@beraswap-dex/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

export const VLX_WAG_FARM_PID = chainId === MAINNET ? 1 : 1 // WAG-VLX (2)
export const VLX_USDT_FARM_PID = chainId === MAINNET ? 1 : 1 // BUSD-VLX (3)

const farms: SerializedFarmConfig[] =
  chainId === MAINNET
    ? [
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'AbstraDEX',
          lpAddresses: {
            7000: '0x786214Ee3EB0fE1d96AbBbaaADb06E869036f862',
            80084: '',
          },
          token: serializedTokens.syrup,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,
          lpSymbol: 'ZETA-VEN LP',
          lpAddresses: {
            7000: '0x336493c4f8b238124387e2f6c857f0c5a0a237b2',
            80084: '',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 2,
          v1pid: 2,
          lpSymbol: 'ETH-USDC LP',
          lpAddresses: {
            7000: '0x3109cd8cfb11931974f916f68f790661e29d023a',
            80084: '',
          },
          token: serializedTokens.usdc,
          quoteToken: serializedTokens.weth,
        },
      ]
    : [
        /**
         * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
         */
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'BRS',
          lpAddresses: {
            7000: '',
            80084: '0x8790933527cff3d07a70119b24307a78478fbdfd',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,
          lpSymbol: 'BRS/WBERA LP',
          lpAddresses: {
            7000: '',
            80084: '0x3436be8107df572c414e8e6d05f95063607f9e08',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'BERA/BRS LP',
        //   lpAddresses: {
        //     7000: '',
        //     80084: '0x3436be8107df572c414e8e6d05f95063607f9e08',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        {
          pid: 3,
          v1pid: 3,
          lpSymbol: 'NECT/WBERA LP',
          lpAddresses: {
            7000: '',
            80084: '0x626a87d003e8d599b17b8c15ad8cc654d9abfb96',
          },
          token: serializedTokens.nect,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 4,
          v1pid: 4,
          lpSymbol: 'BRS/HONEY LP',
          lpAddresses: {
            7000: '',
            80084: '0x9de9f616c2991d31f25cc4792bfb8b969bb15ece',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.honey,
        },
        {
          pid: 5,
          v1pid: 5,
          lpSymbol: 'WBERA/HONEY LP',
          lpAddresses: {
            7000: '',
            80084: '0x32ca7877a6aa45d5fdb92168f9dc969e04e8094a',
          },
          token: serializedTokens.weth,
          quoteToken: serializedTokens.honey,
        },
      ]

export default farms
