import React, {
  // useEffect,
  useState,
} from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { Box, Flex } from '../../../../components/Box'
// import { ChevronDownIcon, WalletFilledIcon } from '../../../../components/Svg'
import {
  UserMenuProps,
  variants,
  // variants
} from './types'
// import MenuIcon from './MenuIcon'
import { UserMenuItem } from './styles'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { ChevronDownIcon, CloseIcon, MetamaskIcon } from '../../../../components/Svg'
import MenuIcon from './MenuIcon'

export const StyledUserMenu = styled(Flex).attrs({ height: ['36px', , , , '48px'] })`
  align-items: center;
  background-color: rgba(189, 118, 32, 1);
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: inline-flex;
  position: relative;
  padding: 5px;
  border-radius: 10px;

  &:hover {
    opacity: 0.65;
  }
`

export const LabelText = styled.div`
  color: white;
  display: none;
  font-weight: 600;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`

const Menu = styled.div<{ isOpen: boolean; isWrongNetWork: boolean }>`
  background-color: ${({ isWrongNetWork }) => (!isWrongNetWork ? 'var(--primary-color)' : 'transparent')};
  pointer-events: auto;
  visibility: visible;
  z-index: 1001;
  border-radius: 10px;
  box-shadow: var(--modal-box-shadow);
  width: 100%;
  max-width: 345px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 407px;
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  avatarSrc,
  variant = variants.DEFAULT,
  isWrongNetWork,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 7)}...${account.substring(account.length - 7)}` : null
  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
  })

  // const showDropdownMenu = () => {
  //   setIsOpen(true)
  // }

  // const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
  //   const target = evt.target as Node
  //   if (target && !tooltipRef?.contains(target)) {
  //     setIsOpen(false)
  //     evt.stopPropagation()
  //   }
  // }

  const toggleDropdownMenu = () => setIsOpen((s) => !s)

  useOnClickOutside(
    {
      current: targetRef,
    },
    () => setIsOpen(false),
  )

  // useEffect(() => {
  //   targetRef?.addEventListener('mouseenter', showDropdownMenu)
  //   targetRef?.addEventListener('mouseleave', hideDropdownMenu)

  //   return () => {
  //     targetRef?.removeEventListener('mouseenter', showDropdownMenu)
  //     targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
  //   }
  // }, [targetRef, tooltipRef, setIsOpen])

  return (
    // <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
    //   <StyledUserMenu
    //     // onTouchStart={() => {
    //     //   setIsOpen((s) => !s)
    //     // }}
    //     onClick={() => setIsOpen(true)}
    //   >
    //     {/* {isWrongNetWork ? null : <WalletFilledIcon color="white" width="24px" />} */}

    //     <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
    //     <MetamaskIcon style={{ display: isMobile ? 'block' : 'none' }} color="white" width="24px" />
    //   </StyledUserMenu>
    //   <Menu
    //     style={styles.popper}
    //     ref={setTooltipRef}
    //     {...attributes.popper}
    //     isOpen={isOpen}
    //     isWrongNetWork={isWrongNetWork}
    //   >
    //     <Box
    //     // onClick={() => setIsOpen(false)}
    //     >
    //       {children?.({ isOpen })}
    //     </Box>
    //   </Menu>
    // </Flex>

    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        // onTouchStart={() => {
        //   setIsOpen((s) => !s)
        // }}

        onClick={toggleDropdownMenu}
      >
        <MenuIcon avatarSrc={avatarSrc} variant={variant} />
        <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
        <ChevronDownIcon color="white" width="24px" />
      </StyledUserMenu>
      <Menu
        style={styles.popper}
        ref={setTooltipRef}
        {...attributes.popper}
        isOpen={isOpen}
        isWrongNetWork={isWrongNetWork}
      >
        <Box style={{ position: 'relative' }}>
          {children?.({ isOpen })}
          <CloseIcon
            onClick={toggleDropdownMenu}
            style={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }}
          />
        </Box>
      </Menu>
    </Flex>
  )
}

export default UserMenu
